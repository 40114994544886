<template>
  <div class="row">
    <div class="col-sm-12 mt-2">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-3"></div>
            <div class="col-sm-6">
              <h3 class="text-primary mb-5 text-center">
                Create Category Bisac
              </h3>
              <div class="form-group">
                <label for="name">Bisac Code :</label>
                <input
                  type="text"
                  class="form-control"
                  id="code"
                  placeholder="type category bisac code.."
                  v-model="code"
                />
              </div>
              <div class="form-group">
                <label for="name"
                  >Name<span class="text-danger">*</span> :</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  placeholder="type category bisac name.."
                  v-model="name"
                />
              </div>
              <div class="form-group form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="is_active"
                  v-model="is_active"
                />
                <label class="form-check-label" for="is_active"
                  >Is Active</label
                >
              </div>

              <button
                type="submit"
                class="btn btn-block btn-primary mt-5"
                @click="AddCategoryBisac"
              >
                <i class="fa fa-plus"></i>
                Create Category Bisac
              </button>
            </div>

            <div class="col-sm-3"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'CreateCategoryBisac',
  data() {
    return {
      code: '',
      name: '',
      is_active: true,
    };
  },
  computed: {
    ...mapState({
      successMessage: (state) => state.category_bisac.successMessage,
      errorMessage: (state) => state.category_bisac.errorMessage,
    }),
  },
  watch: {
    successMessage: function() {
      if (!this.successMessage) return;
      this.alertMsg(this.successMessage, 'success');
      this.$router.push('/category-bisac');
    },
    errorMessage: function() {
      if (!this.errorMessage) return;
      this.alertMsg(this.errorMessage, 'error');
      this.errorMessage = '';
    },
  },
  methods: {
    ...mapActions('category_bisac', ['createCategoryBisac']),

    async AddCategoryBisac() {
      let payload = {
        code: this.code,
        name: this.name,
        isActive: this.is_active,
      };

      this.createCategoryBisac(payload);
    },

    alertMsg(msg, type) {
      this.$swal({
        icon: type,
        toast: true,
        position: 'top-end',
        title: msg,
        timer: 10000,
        text: this.message,
      });
    },
  },
};
</script>
